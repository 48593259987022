import { createBasicRepository as createRepository } from "elentari";
import { restApi } from "../../services/api";
import { paginate, query } from "../../lib/feathers";

const companiesRepository = createRepository({
  path: "companies",
  restApi,
  queryTransform: {
    name: query.like("name"),
    ...paginate,
  },
});

export default companiesRepository;
