import React, { useEffect, useState } from "react";
import { PureAutocomplete, autocompleteHelpers } from "elentari";
import { CancelToken } from "apisauce";
const { toOption } = autocompleteHelpers;

const Autocomplete = ({
  input,
  meta,
  repository,
  labelOption,
  loadOptions,
  getOneRepository,
  labelFormat,
  ...props
}) => {
  const [value, setValue] = useState();

  useEffect(() => {
    const source = CancelToken.source();
    const fetch = async () => {
      if (input.value !== null) {
        let value = input.value;

        if (props.isMulti && input.value === "") {
          setValue(null);
          return;
        }

        if (props.isMulti) {
          const values = Array.isArray(value) ? value : value.split(",");
          const responses = await Promise.all(
            values.map(id =>
              repository.getOne({
                id,
                ...(props.allowDeletedAt && {
                  query: {
                    allowDeletedAt: !!props.allowDeletedAt,
                  },
                }),
                options: {
                  cancelToken: source.token,
                },
              })
            )
          );
          const options = responses
            .filter(response => response.ok)
            .map(response =>
              labelFormat
                ? labelFormat(response.data)
                : toOption(labelOption)(response.data)
            );
          setValue(options);
        } else {
          const repo = getOneRepository || repository;
          if (value) {
            const response = await repo.getOne({
              id: value,
              ...(props.allowDeletedAt && {
                query: {
                  allowDeletedAt: !!props.allowDeletedAt,
                },
              }),
              options: {
                cancelToken: source.token,
              },
            });
            if (response.ok) {
              if (labelFormat) {
                const option = labelFormat(response.data);
                setValue(option);
              } else {
                const option = toOption(labelOption)(response.data);
                setValue(option);
              }
            }
          } else {
            setValue(null);
          }
        }
      } else {
        setValue(null);
      }
    };
    fetch();
    return () => source.cancel();
  }, [
    input.value,
    labelOption,
    repository,
    getOneRepository,
    labelFormat,
    props.allowDeletedAt,
    props.isMulti,
  ]);

  const onChange = item => {
    setValue(item);
    input.onChange(
      item
        ? Array.isArray(item)
          ? item.map(item => item.id)
          : item.id
        : undefined
    );
  };

  const touched = meta.touched || props.showError;

  return (
    <PureAutocomplete
      {...props}
      meta={meta}
      error={meta.error && touched}
      textFieldProps={{
        onBlur: input.onBlur,
        helperText: touched && meta.error,
        error: meta.error && touched,
      }}
      helperText={touched && meta.error}
      InputLabelProps={{ shrink: !!value }}
      loadOptions={loadOptions}
      onChange={onChange}
      value={value}
      loadingMessage={() => "Carregando..."}
    />
  );
};

export default Autocomplete;
