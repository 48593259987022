import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import InputUpload from "./InputUpload";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const useStyles = makeStyles(theme => ({
  fileInput: {
    marginTop: theme.spacing(2),
  },
  fileInputRoot: {
    position: "relative",
  },
}));

const UploadFile = ({
  label,
  value = {},
  onChange,
  canRemove = true,
  error,
  accept = "image/*,.pdf",
  helperText,
  disabled = false, // Disabled botão de upload
  disabledDelete = false,
}) => {
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const [inputValue, setInputValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState();
  const classes = useStyles();
  // TODO Verificar uma forma melhor de permitir ou não o Download do arquivo
  const canDownload = value.name && !value.size;
  disabledDelete = disabledDelete || !value.name;

  const handleChange = value => {
    onChange(value);
    setInputValue(value);
  };

  const handleRemove = () => {
    onChange({ name: "", path: "" });
    setInputValue({ name: "", path: "" });
  };

  const renderStartAdornment = () => (
    <InputAdornment position="start" disablePointerEvents={disabled}>
      <InputUpload
        accept={accept}
        label="Upload"
        value={inputValue.name || ""}
        onChange={handleChange}
        disabled={disabled}
        onError={() =>
          setErrorMessage("Erro no upload de arquivo, verifique a extensão!")
        }
      />
    </InputAdornment>
  );

  const renderEndAdornment = () => (
    <InputAdornment position="end">
      {canRemove && (
        <IconButton
          title="Remover"
          onClick={handleRemove}
          disabled={disabled || disabledDelete}
        >
          <DeleteIcon />
        </IconButton>
      )}
      {canDownload && (
        <IconButton
          title="Abrir"
          variant="contained"
          disabled={!canDownload}
          component={IconButton}
          onClick={() => window.open(value.path)}
        >
          <OpenInNewIcon />
        </IconButton>
      )}
    </InputAdornment>
  );

  return (
    <div className={classes.fileInputRoot}>
      <TextField
        InputProps={{
          className: classes.fileInput,
          readOnly: true,
          shrink: true,
          startAdornment: renderStartAdornment(),
          endAdornment: renderEndAdornment(),
        }}
        value={inputValue.name ? inputValue.name : "Nenhum arquivo selecionado"}
        label={label}
        fullWidth
        error={error || (!inputValue && errorMessage)}
        helperText={helperText || (!inputValue && errorMessage)}
      />
    </div>
  );
};

export default UploadFile;
