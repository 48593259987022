import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useEntityManager } from "elentari";
import OpenInNew from "@material-ui/icons/OpenInNew";
import checklistsRepository from "./checklistsRepository";
import {
  formatCNPJ,
  formatCPF,
  formatDate,
  formatDateTime,
} from "../../utils/formaters";

const useStyles = makeStyles(() => ({
  value: {
    wordWrap: "break-word",
    overflow: "hidden",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  selected: {
    backgroundColor: "#e0e0e0",
  },
}));

const ChecklistCard = props => {
  const classes = useStyles();
  const [fetching, setFetching] = useState(false);
  const entityManager = useEntityManager({
    repository: checklistsRepository,
  });

  const handleImprimir = async item => {
    if (item.pdf) {
      return window.open(item.pdf);
    } else {
      setFetching(true);
      const response = await checklistsRepository.regeneratePdf(item.id);
      console.log(response);
      if (response.ok) {
        window.open(response.data);
      }
      setFetching(false);
    }
  };

  const item = entityManager.item;
  const renderItems = (cheklistItems = []) => {
    const formatAnswer = answer => {
      if (answer === "POSITIVE") {
        return "Sim";
      } else if (answer === "NEGATIVE") {
        return "Não";
      } else {
        return "Não se Aplica";
      }
    };

    return (
      <>
        <TableRow
          className={classes.tableRow}
          classes={{ selected: classes.selected }}
          selected
        >
          <TableCell colSpan="3">Descrição</TableCell>
          <TableCell>Em conformidade</TableCell>
          <TableCell>Imagem</TableCell>
        </TableRow>
        {cheklistItems.map((checklistItem, index) => (
          <TableRow key={checklistItem.id}>
            <TableCell colSpan="3">
              <Typography variant="body1" className={classes.value}>
                {index + 1}. {checklistItem.description}
              </Typography>
              {checklistItem.expirationDate && (
                <Typography component="p" variant="caption">
                  Valido até: {formatDate(checklistItem.expirationDate)}
                </Typography>
              )}
              {checklistItem.note && (
                <Typography component="p" variant="caption">
                  Observação: {checklistItem.note}
                </Typography>
              )}
            </TableCell>
            <TableCell>{formatAnswer(checklistItem.answer)}</TableCell>
            <TableCell>
              {checklistItem.image && (
                <IconButton
                  title="Abrir"
                  variant="contained"
                  onClick={() => window.open(checklistItem.image)}
                >
                  <OpenInNew />
                </IconButton>
              )}
              {checklistItem.image2 && (
                <IconButton
                  title="Abrir"
                  variant="contained"
                  onClick={() => window.open(checklistItem.image2)}
                >
                  <OpenInNew />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const renderPF = item => {
    return (
      <>
        <TableRow>
          <TableCell colSpan="3">
            <Typography variant="caption">IDENTIFICADOR</Typography>
            <Typography variant="body1" className={classes.value}>
              {item.identifier}
            </Typography>
          </TableCell>
          <TableCell colSpan="2">
            <Typography variant="caption">REVISÃO</Typography>
            <Typography variant="body1" className={classes.value}>
              {item.revision}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan="3">
            <Typography variant="caption">CPF</Typography>
            <Typography variant="body1" className={classes.value}>
              {formatCPF(item.driverCPF)}
            </Typography>
          </TableCell>
          <TableCell colSpan="1">
            <Typography variant="caption">DATA</Typography>
            <Typography variant="body1" className={classes.value}>
              {formatDateTime(item.createdAt)}
            </Typography>
          </TableCell>
          <TableCell colSpan="1">
            <Typography variant="caption">VALIDADE</Typography>
            <Typography variant="body1" className={classes.value}>
              {formatDate(item.expirationDays)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan="3">
            <Typography variant="caption">NOME MOTORISTA</Typography>
            <Typography variant="body1" className={classes.value}>
              {item.driverName}
            </Typography>
          </TableCell>
          <TableCell colSpan="2">
            <Typography variant="caption">PLACA CAVALO</Typography>
            <Typography variant="body1" className={classes.value}>
              {item.plateTruck}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan="3">
            <Typography variant="caption">CNH</Typography>
            <Typography variant="body1" className={classes.value}>
              {item.driverCNH}
            </Typography>
          </TableCell>
          <TableCell width="25%" colSpan="2">
            <Typography variant="caption">PLACA REBOQUE</Typography>
            <Typography variant="body1" className={classes.value}>
              {item.plateReboque}
            </Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const renderPJ = item => {
    return (
      <>
        <TableRow>
          <TableCell colSpan="3">
            <Typography variant="caption">IDENTIFICADOR</Typography>
            <Typography variant="body1" className={classes.value}>
              {item.identifier}
            </Typography>
          </TableCell>
          <TableCell colSpan="2">
            <Typography variant="caption">REVISÃO</Typography>
            <Typography variant="body1" className={classes.value}>
              {item.revision}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan="3">
            <Typography variant="caption">CNPJ</Typography>
            <Typography variant="body1" className={classes.value}>
              {formatCNPJ(item.cnpj)}
            </Typography>
          </TableCell>
          <TableCell colSpan="1">
            <Typography variant="caption">DATA</Typography>
            <Typography variant="body1" className={classes.value}>
              {formatDateTime(item.createdAt)}
            </Typography>
          </TableCell>
          <TableCell colSpan="1">
            <Typography variant="caption">VALIDADE</Typography>
            <Typography variant="body1" className={classes.value}>
              {formatDate(item.expirationDays)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan="3">
            <Typography variant="caption">RAZÃO SOCIAL</Typography>
            <Typography variant="body1" className={classes.value}>
              {item.corporateName}
            </Typography>
          </TableCell>
          <TableCell colSpan="2">
            <Typography variant="caption">DEPARTAMENTO</Typography>
            <Typography variant="body1" className={classes.value}>
              {item.department}
            </Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Paper>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <TableRow
                    className={classes.tableRow}
                    classes={{ selected: classes.selected }}
                    selected
                  >
                    <TableCell width="25%" align="center">
                      {item.company ? (
                        <img alt="logo" src={item.company?.logo} />
                      ) : null}
                    </TableCell>
                    <TableCell colSpan="4" align="center">
                      <Typography variant="h5">{item.name}</Typography>
                    </TableCell>
                  </TableRow>
                  {item.cnpj ? renderPJ(item) : renderPF(item)}
                  {renderItems(item.checklistItems)}
                  <TableRow
                    className={classes.tableRow}
                    classes={{ selected: classes.selected }}
                    selected
                  >
                    <TableCell colSpan="5">Observações</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="5">
                      <Typography variant="body1" className={classes.value}>
                        {item.note}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              fullWidth
              type="button"
              variant="outlined"
              onClick={() => props.history.goBack()}
            >
              Voltar
            </Button>
          </Grid>
          <Grid item>
            <>
              <Button
                fullWidth
                type="button"
                variant="contained"
                color="primary"
                onClick={() => handleImprimir(item)}
              >
                {fetching ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                    style={{ marginRight: 24, marginLeft: 24 }}
                  />
                ) : (
                  "Imprimir"
                )}
              </Button>
            </>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChecklistCard;
