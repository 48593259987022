import { AbilityBuilder } from "@casl/ability";

const subjectName = subject =>
  !subject || typeof subject === "string" ? subject : subject.__type;

export default user => {
  return AbilityBuilder.define({ subjectName }, (can, cannot) => {
    if (!user) return;

    if (user.role === "TI_ADMIN") {
      can("manage", "all");
    }
    if (user.role === "ADMIN") {
      can("manage", "all");
    }
    if (user.role === "USER") {
      cannot("manage", "all");
      can("manage", "checklists");
      can(["read", "update"], "users");
    }
  });
};
