import * as R from "ramda";

export default repository => {
  const _create = repository.create;
  repository.create = data => {
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    return _create(formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
  const _update = repository.update;
  repository.update = data => {
    const formData = new FormData();
    formData.id = data.id;
    Object.keys(data).forEach(key =>
      !R.isNil(data[key]) ? formData.append(key, data[key]) : null
    );
    return _update(formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
};
