import React from "react";
import { autocompleteHelpers } from "elentari";
import companiesRepository from "./companiesRepository";
import PureAutocomplete from "../../Components/PureAutocomplete";
const { toOption } = autocompleteHelpers;

export const loadCompanies = async inputValue => {
  const response = await companiesRepository.list({
    query: {
      name: inputValue,
      order: "name asc",
    },
    paginate: {
      limit: 10,
    },
  });
  if (response.ok) {
    return response.data.map(toOption("name"));
  }
  return [];
};

const CompaniesAutoComplete = props => (
  <PureAutocomplete
    {...props}
    repository={companiesRepository}
    labelOption="name"
    loadOptions={loadCompanies}
  />
);

export default CompaniesAutoComplete;
