import moment from "moment";

const removeAscDesc = text =>
  text
    .replace("asc", "")
    .replace("desc", "")
    .trim();
const ascDescNumber = (text = "asc") => (text.includes("desc") ? -1 : 1);
export const paginate = {
  limit: value => ["$limit", value],
  skip: value => ["$skip", value],
  sort: value => [`$sort[${removeAscDesc(value)}]`, ascDescNumber(value)],
  order: value => [`$sort[${removeAscDesc(value)}]`, ascDescNumber(value)],
};

export const query = {
  like: field => value => [`${field}[$like]`, `%${value}%`],
  ilike: field => value => [`${field}[$iLike]`, `%${value}%`],
  ilikeOnlyAlpahNumeric: field => value => [
    `${field}[$iLike]`,
    `%${value.replace(/\W/g, "")}%`,
  ],
  startsWith: field => value => [`${field}[$iLike]`, `${value}%`],
  inDay: field => value => [
    [
      `${field}[$gte]`,
      moment(value)
        .startOf("day")
        .local()
        .toISOString(),
    ],
    [
      `${field}[$lte]`,
      moment(value)
        .endOf("day")
        .local()
        .toISOString(),
    ],
  ],
};

export const softDeleteStrategy = {
  remove: () => ({ deletedAt: new Date().toISOString() }),
  undoRemove: () => ({ deletedAt: null }),
  fetchList: () => ({}),
};
