import React, { useContext } from "react";
import {
  List,
  Filter,
  useListRepository,
  useFilter,
  TextField,
  MaskedField,
} from "elentari";
import { Field } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import checklistsRepository from "./checklistsRepository";
import { useAbility } from "../users";
import { getListActions } from "../../lib/crudAuthorization";
import { formatDate, formatDateTime } from "../../utils/formaters";
import UsersAutocomplete from "../users/UsersAutocomplete";
import { DatePicker } from "../../Components";
import { AuthContext } from "elentari";
import Tooltip from "@material-ui/core/Tooltip";

const ChecklistsFilter = () => {
  const filter = useFilter({});

  return (
    <Filter {...filter} labels={{ find: "Buscar", clear: "Limpar" }}>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Field
            fullWidth
            name="departmentDriverName"
            label="Motorista/Departamento"
            component={TextField}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Field
            fullWidth
            name="name"
            label="Checklist"
            component={TextField}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Field
            fullWidth
            name="responsableId"
            label="Responsavel"
            component={UsersAutocomplete}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Field
            fullWidth
            name="plateTruck"
            parse={value => (value ? value.toUpperCase() : "")}
            label="Placa Cavalo"
            mask={[
              /[a-zA-Z]/,
              /[a-zA-Z]/,
              /[a-zA-Z]/,
              "-",
              /\d/,
              /\w/,
              /\d/,
              /\d/,
            ]}
            component={MaskedField}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Field
            fullWidth
            name="createdAt"
            label="Data de Realização"
            component={DatePicker}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Field
            fullWidth
            name="expirationDays"
            label="Data de Validade"
            component={DatePicker}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

const Checklists = ({ ...props }) => {
  const ability = useAbility();
  const context = useContext(AuthContext);
  const listOptions = {
    defaultOrder: "id desc",
    fields: {
      plateTruck: {
        label: "Placa da Carreta",
        format: value => (value ? value : "N/A"),
      },
      departmentDriverName: {
        label: "Motorista/Departamento",
      },
      name: {
        label: "Checklist",
        format: name => formatName(name),
      },
      createdAt: {
        label: "Data de Realização",
        format: value => formatDateTime(value),
      },
      expirationDays: {
        label: "Data Validade",
        format: value => formatDate(value),
      },
      responsable: {
        label: "Responsável",
        format: value => (value ? value.name : "-"),
      },
    },
  };

  const formatName = name => {
    if (name && name.length > 20) {
      return (
        <Tooltip title={name}>
          <span>{name.substring(0, 20)}...</span>
        </Tooltip>
      );
    }
    return name;
  };

  const listHook = useListRepository({
    repository: checklistsRepository,
    path: "checklists",
    defaultSort: "id desc",
    query: [["companyId", context?.user.company_id]],
  });

  listHook.state.list = listHook.state.list.map(item => ({
    ...item,
    departmentDriverName: item.department || item.driverName,
  }));

  const actions = getListActions(ability, listHook, "checklists");

  return (
    <>
      <ChecklistsFilter />
      <List
        {...props}
        {...listHook}
        {...actions}
        onClickEdit={null}
        onClickView={listHook.onClickEdit}
        onClickNew={null}
        removeItem={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  );
};

export default Checklists;
