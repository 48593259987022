import { restApi } from "../../services/api";
import { createBasicRepository as createRepository } from "elentari";
import { query, paginate } from "../../lib/feathers";
import addUploadCapabilities from "../../lib/addUploadCapabilities";

const usersRepository = createRepository({
  path: "users",
  restApi,
  queryTransform: {
    name: query.ilike("name"),
    email: query.ilike("email"),
    ...paginate,
  },
});
addUploadCapabilities(usersRepository);

export default usersRepository;
