import React, { useContext, useState } from "react";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { AppWrap, RouteByMenu, AuthContext } from "elentari";
import { makeStyles } from "@material-ui/core/styles";
import AccountBox from "@material-ui/icons/AccountBox";
import { Switch, Redirect, Route, useHistory } from "react-router-dom";
import * as R from "ramda";
import { useAbility } from "./modules/users";
import { UsersList, UserForm } from "./modules/users";
import { Checklists } from "./modules/checklists";
import logo from "./images/g10-logo-inverter.png";
import ChecklistCard from "./modules/checklists/ChecklistCard";
import { MenuItem, MenuList, Popover, Typography } from "@material-ui/core";
import UserSimpleForm from "./modules/users/UserSimpleForm";

const useStyles = makeStyles(theme => ({
  logo: {
    maxWidth: "100%",
  },
  logoOpen: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 128,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoClose: {
    width: 40,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const allMenuItems = [
  {
    name: "checklists",
    label: "Checklists",
    icon: PlaylistAddCheckIcon,
    pathname: "/checklists",
    list: Checklists,
    form: ChecklistCard,
  },
  {
    name: "users",
    label: "Usuários",
    icon: AccountBox,
    pathname: "/users",
    list: UsersList,
    form: UserForm,
  },
];

const breadcrumbsPropertiesMap = {
  simpleForm: "Edição Simples",
};

export const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(localStorage.toggleDrawer === "1");
  const [menu, setMenu] = useState(false);
  const authContext = useContext(AuthContext);
  const user = authContext.user;

  const onToggleDrawer = status => {
    setOpen(status === "open");
  };

  let menuItems = [];
  const abilities = useAbility();
  if (abilities) {
    menuItems = allMenuItems.filter(({ name }) => {
      if (!user.role.includes("ADMIN") && name === "users") return false;
      return abilities.can("read", name);
    });
  }

  const defaultRedirect = R.pathOr("/checklists", [0, "pathname"], menuItems);

  return (
    <AppWrap
      onToggleDrawer={onToggleDrawer}
      logo={
        <div className={open ? classes.logoOpen : classes.logoClose}>
          <img alt="logo G10" src={logo} className={classes.logo} />
        </div>
      }
      pathReadableMap={breadcrumbsPropertiesMap}
      userAvatarProps={{
        action: (
          <div style={{ textAlign: "right" }}>
            <Typography variant="caption">
              <b>OPÇÕES</b>
            </Typography>
          </div>
        ),
        label: "Olá,",
        onActionClick: () => setMenu(true),
      }}
      menuItems={menuItems}
    >
      <Popover
        anchorEl={null}
        className={classes.popover}
        keepMounted
        open={menu}
        onClose={() => setMenu(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuList dense={true}>
          <MenuItem
            onClick={() => {
              history.push(`/users/simpleForm/${user.id}`);
              setMenu(false);
            }}
          >
            Editar Dados
          </MenuItem>
          <MenuItem
            onClick={() => {
              authContext.logout && authContext.logout();
            }}
          >
            Sair
          </MenuItem>
        </MenuList>
      </Popover>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to={defaultRedirect} />}
        />
        <Route exact path="/users/simpleForm/:id" component={UserSimpleForm} />
        <RouteByMenu menuItems={menuItems} />
      </Switch>
    </AppWrap>
  );
};
