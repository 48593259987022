import React from "react";
import { Login } from "elentari";
import api from "../../services/api";
import * as R from "ramda";
import logo from "../../images/g10-logo.png";
import { Grid } from "@material-ui/core";

export const handleLogin = ({ login, setToken = R.empty }) => async ({
  username: email,
  password,
}) => {
  if (!email || !password) return;
  const response = await login({
    email,
    password,
  });
  if (response.ok) {
    if (!response.data.user) {
      return { ok: false };
    }
    if (!response.data.accessToken) {
      return { ok: false, message: "Token não encontrado" };
    }
    setToken(response.data.accessToken);
    return {
      ok: true,
      token: response.data.accessToken,
      id: response.data.user.id,
      company_id: response.data.user.companyId,
      email: response.data.user.email,
      name: response.data.user.name,
      role: response.data.user.role,
    };
  }
  return { ok: false };
};
const LoginForm = ({ history }) => {
  return (
    <Login
      logo={
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="center">
            <img alt="logo" src={logo} style={{ marginBottom: 20 }} />
          </Grid>
        </Grid>
      }
      history={history}
      onSubmit={handleLogin(api)}
      requiredLabel="Obrigatório"
      usernameLabel="Usuário"
      submitLabel="Entrar"
      passwordLabel="Senha"
      recoverPasswordLabel="Recuperar Senha"
    />
  );
};

export default LoginForm;
