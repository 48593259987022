import React from "react";
import UploadFile from "./UploadFile";

const UploadFileField = ({ meta, input, ...props }) => {
  return (
    <UploadFile
      value={input.value}
      onChange={input.onChange}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error?.name}
      {...props}
    />
  );
};

export default UploadFileField;
