import { createBasicRepository as createRepository } from "elentari";
import { restApi } from "../../services/api";
import { query, paginate } from "../../lib/feathers";

const checklistsRepository = createRepository({
  path: "checklists",
  restApi,
  queryTransform: {
    departmentDriverName: query.ilike("departmentDriverName"),
    name: query.ilike("name"),
    plateTruck: query.ilikeOnlyAlpahNumeric("plateTruck"),
    createdAt: query.inDay("createdAt"),
    expirationDays: query.inDay("expirationDays"),
    ...paginate,
  },
});

checklistsRepository.regeneratePdf = id => {
  return restApi.get(`checklist-print/${id}`);
};

export default checklistsRepository;
