import React from "react";
import { useDropzone } from "react-dropzone";
import { IconButton } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import { green } from "@material-ui/core/colors";

const InputUpload = ({ accept, label, onChange, value, disabled, onError }) => {
  const onDrop = async acceptedFiles => {
    if (acceptedFiles.length) {
      const [file] = acceptedFiles;
      if (file) {
        onChange && onChange(file);
      }
    } else {
      onError && onError();
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    noDrag: true,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <IconButton
        title={label || "Selecionar arquivo"}
        variant="outlined"
        color={value ? "default" : "primary"}
        style={{ color: value ? green[500] : "" }}
        disabled={disabled}
      >
        {value ? <CloudDoneIcon /> : <CloudUploadIcon />}
      </IconButton>
    </div>
  );
};

export default InputUpload;
