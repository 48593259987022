import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { Field } from "react-final-form";
import { AuthContext, CrudForm, useEntityManager, TextField } from "elentari";
import usersRepository from "./usersRepository";
import { Email, UploadFileField } from "../../Components";
import * as yup from "yup";
import yupValidation from "../../lib/yupValidation";
import { useHistory, useParams } from "react-router-dom";

const userSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email Inválido")
    .required("Obrigatório"),
});

const validate = yupValidation(userSchema);

const UserSimpleForm = props => {
  const history = useHistory();
  const params = useParams();
  const id = +params.id;
  const { user } = useContext(AuthContext);

  if (id !== user.id) {
    history.goBack();
  }

  const entityManager = useEntityManager({
    repository: usersRepository,
  });

  if (
    entityManager.item.signature &&
    typeof entityManager.item.signature === "string"
  ) {
    entityManager.item.signature = {
      name: entityManager.item.signature,
      path: entityManager.item.signature,
    };
  }

  const handleSubmit = async data => {
    if (!(data.signature instanceof File)) {
      delete data.signature;
    }
    delete user.email;
    return entityManager.onSubmit(data);
  };

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        onGoBack={history.goBack}
        onSubmit={handleSubmit}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Email disabled />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Field
              fullWidth
              name="businessRole"
              label="Cargo"
              component={TextField}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Field
              label="Assinatura"
              name="signature"
              accept="image/*"
              component={UploadFileField}
            />
          </Grid>
        </Grid>
      </CrudForm>
    </>
  );
};

export default UserSimpleForm;
