import moment from "moment";

export const formatDate = date => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  }
  return "-";
};

export const formatDateTime = date => {
  if (date) {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  }
  return "-";
};

export const formatCPF = cpf => {
  if (cpf) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  return "";
};

export const formatCNPJ = cnpj => {
  if (cnpj) {
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }
  return "";
};
