import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { Field } from "react-final-form";
import {
  Select,
  CrudForm,
  TextField,
  useEntityManager,
  AuthContext,
} from "elentari";
import usersRepository from "./usersRepository";
import { ROLES } from "./UsersList";
import { useAbility } from ".";
import { Email, UploadFileField } from "../../Components";
import * as yup from "yup";
import yupValidation from "../../lib/yupValidation";
import CompaniesAutoComplete from "../companies/CompaniesAutoComplete";

const userSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email Inválido")
    .required("Obrigatório"),
  name: yup.string().required("Obrigatório"),
  role: yup.string().required("Obrigatório"),
  password: yup
    .string()
    .notRequired()
    .when("isNew", (isNew, passwordSchema) =>
      isNew ? passwordSchema.required("Obrigatório") : passwordSchema
    ),
});

const validate = yupValidation(userSchema);

const UserForm = props => {
  const abilities = useAbility();
  const context = useContext(AuthContext);
  const isTiAdmin = context?.user?.role === "TI_ADMIN";

  const filteredRoles = ROLES.filter(role => {
    return isTiAdmin || role.value !== "TI_ADMIN";
  });

  const entityManager = useEntityManager({
    repository: usersRepository,
  });
  const cannotUpdate =
    abilities.cannot("update", "users") && !entityManager.isNew;
  entityManager.item.isNew = entityManager.isNew;
  const isNew = entityManager.isNew;

  if (!isNew) {
    if (
      entityManager.item.signature &&
      typeof entityManager.item.signature === "string"
    ) {
      entityManager.item.signature = {
        name: entityManager.item.signature,
        path: entityManager.item.signature,
      };
    }
  }

  const handleSubmit = async data => {
    if (!cannotUpdate) {
      if (!(data.signature instanceof File)) {
        delete data.signature;
      }
      return entityManager.onSubmit(data);
    }
  };

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        disableSubmit={cannotUpdate}
        onSubmit={handleSubmit}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Field fullWidth name="name" label="Nome" component={TextField} />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Email />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Field
              fullWidth
              name="businessRole"
              label="Cargo"
              component={TextField}
            />
          </Grid>
          <Grid item sm={isNew ? 3 : 4} xs={12}>
            <Field
              fullWidth
              name="role"
              label="Perfil"
              component={Select}
              options={filteredRoles}
              hideEmpty
            />
          </Grid>
          <Grid item sm={isNew ? 3 : 4} xs={12}>
            <Field
              fullWidth
              name="companyId"
              label="Empresa"
              disabled={!isTiAdmin}
              component={CompaniesAutoComplete}
            />
          </Grid>
          <Grid item sm={isNew ? 3 : 4} xs={12}>
            <Field
              label="Assinatura"
              name="signature"
              accept="image/*"
              disabled={cannotUpdate}
              component={UploadFileField}
            />
          </Grid>
          {isNew && (
            <Grid item sm={3} xs={12}>
              <Field
                fullWidth
                name="password"
                disabled={cannotUpdate}
                label="Senha"
                type="password"
                component={TextField}
              />
            </Grid>
          )}
        </Grid>
      </CrudForm>
    </>
  );
};

export default UserForm;
